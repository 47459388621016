<template>
    <div class="bar-loader">
        <div class="fill"></div>
    </div>

    <Menu ref="Menu" class="menu" v-if="BBAT" />
    
    <div class="content" :class="{ full: !BBAT }" @scroll="Scroll">
        <Bar ref="Bar" class="bar" :scrolled="Scrolled" v-if="BBAT" />

        <div class="loader" v-if="Loader">
            <ProgressSpinner />
        </div>

        <router-view class="router" :class="{ stack: this.$route.meta.Stack }"/>
        
        <Intro v-if="Intro" />
    </div>
</template>

<script>
import Pusher from 'pusher-js'

import Menu from '@/components/Menu.vue';
import Bar from '@/components/Bar.vue';
import Intro from '@/components/Intro.vue';

import ProgressSpinner from 'primevue/progressspinner';

import { Haptics } from '@capacitor/haptics';

import { Device } from '@capacitor/device';

export default {
    name: 'App',

    components: {
        Menu,
        Bar,
        Intro,
        ProgressSpinner
    },

    data () {
        return {
            Loader: false,
            Intro: false,
            BBAT: localStorage.getItem('BBAT'),
            Year: localStorage.getItem('BBY'),
            Scrolled: false,
            Platform: false
        }
    },

    mounted () {
        this.SetPlatform()
    },

    methods: {
        async SetPlatform () {
            await Device.getInfo().then(e => {
                this.Platform = e.platform
            })
        },

        Scroll () {
            if(document.querySelector('.content').scrollTop > 0) {
                this.Scrolled = true
            } else {
                this.Scrolled = false
            }
        },

        SetPusher (e) {
            this.PusherStream = new Pusher('cab193158f187399fe70', {
                cluster: 'eu'
            })

            this.PusherStream.subscribe(e)
        },

        async Vibrate (e) {
            var vibrateDuration = 10

            if(e) {
                vibrateDuration = e
            }

            await Haptics.vibrate({
                duration: vibrateDuration
            });
        },

        async Logout () {
            await this.$http.post('https://bouwbond.app/api/v1/auth/logout').then(() => {
                localStorage.clear()
                location.href = '/login'
            })
        },

        SetLoader (e) {
            if(e) {
                this.Loader = true
            } else {
                this.Loader = false
            }
        },

        SetIntro (e) {
            if(e) {
                this.Intro = true
            } else {
                this.Intro = false
            }
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&display=swap');

:root {
    --app-background: #f7f8fc;
}

* {
    font-family: "Baloo 2", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    margin: 0;
    padding: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #333;
    box-sizing: border-box;
}

html, body, #app {
    width: 100%;
    height: 100%;
    background: #f7f8fc;
}

#app {
    display: flex;
    overflow: hidden;
}

.menu {
    width: 300px;
    border-right: solid 1px #eff0f6;
}

.content {
    position: relative;
    width: calc(100% - 200px);
    background: #f7f8fc;
    overflow: auto;
}

.content.full {
    width: 100%;
}

.content .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: hsl(0deg 0% 0% / 25%);
    z-index: 999;
}

.content .bar {
    height: 250px;
    background: #009fe3;
}

.content .router {
    width: 100%;
    max-width: 1200px;
    height: calc(100% - 250px);
    padding: 0 50px;
    margin: 0 auto;
    transition: .25s;
}

.content .router.stack {
    margin: -35px auto 35px auto;
}

.content::-webkit-scrollbar {
    width: 10px;
    background: #f7f8fc;
}

.content::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #888;
    border: solid 3px transparent;
}

.content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
    border: solid 3px transparent;
}

.bar-loader {
    position: fixed;
    top: -5px;
    left: 0;
    width: 100%;
    height: 5px;
    background: #eeeeee;
    transition: .25s;
}

.bar-loader.active {
    top: 0;
}

.bar-loader .fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 5px;
    background: #009fe3;
    transition: .25s;
}

.bar-loader .fill.third {
    width: 33%;
}

.bar-loader .fill.full {
    width: 100%;
}

.noselect, a {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

img {
    pointer-events: none;
}





@media only screen and (max-width: 1000px) {
    * {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;

        -webkit-touch-callout: none;
        
        overscroll-behavior-y: none;
    }

    html, body, #app  {
        background: var(--app-background);
    }

    .content {
        width: 100%;
    }
    
    .content .router {
        height: 100% !important;
        padding: 0 25px;
    }
}
</style>