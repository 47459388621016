<template>
    <div class="intro">
        <div class="intro-content">
            <span class="title">Oeps, het lijkt erop dat je nog geen toegang hebt tot deze module!</span>
            <span class="text">Om volledig gebruik te kunnen maken van het portaal van Stichting Bouwbond, dien je eerst een jaar te unlocken. Je betaalt eenmalig per jaar waarin je toetsingen wil verrichten. Je hebt dit veilig en snel geregeld en profiteert direct van de voordelen:</span>
            <div class="usp">
                <span class="usp-item">Zonder administratieve kennis wel op de hoogte</span>
                <span class="usp-item">Persoonlijke aanbevelingen waar je echt wat mee kan</span>
                <span class="usp-item">Intuïtief portaal waar iedereen mee aan de slag kan</span>
                <span class="usp-item">Voorkom boetes of naheffingen van de Belastingdienst</span>
                <span class="usp-item">Krijg een streepje voor bij opdrachtgevers</span>
            </div>
            <span class="button" @click="this.$root.SetIntro(false)">Krijg direct volledige toegang</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Intro'
}
</script>

<style scoped>
.intro {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000080;
    z-index: 888;
}

.intro .intro-content {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: auto;
    padding: 35px;
    background: #fff;
    border-radius: 10px;
}

.intro .intro-content .title {
    font-size: 24px;
    font-weight: 600;
}

.intro .intro-content .usp {
    display: flex;
    flex-direction: column;
}

.intro .intro-content .usp .usp-item {
    display: list-item;
}

.intro .intro-content .button {
    width: fit-content;
    display: flex;
    background: orange;
    padding: 10px 25px;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
}
</style>