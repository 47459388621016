<template>
    <div class="item">
        <div class="tab">
            <div class="block">
                <span class="bubble">Resultaat</span>

                <span class="title">Jouw score voor deze check</span>
                <span class="subtitle">Op basis van jouw input kom je op deze score uit. Dit is op basis van onze interpretaties op vrijgegeven documentatie van de Belastingdienst. Heb je geen bijlagen toegevoegd? Dan kun je dit in een nieuwe ZZP Status Check alsnog doen. Ook wanneer je met onderstaande aanbevelingen aan de slag bent gegaan kun je eenvoudig een nieuwe ZZP Status Check starten.</span>
                <span class="score" :class="{ green: Score >= 60, orange: Score < 60 }" v-if="Score">
                    <span class="top">{{ Number(Score).toFixed(0) }}</span>
                    <span class="bottom">/ 100</span>
                    <img v-if="Score >= 60" class="illustration" src="@/assets/Illustration/Tevreden.png" alt="Tevreden">
                    <img v-if="Score < 60" class="illustration" src="@/assets/Illustration/Verdrietig.png" alt="Verdrietig">
                </span>

                <div class="advices">
                    <div class="advice" v-for="(Advice, i) in Advices" :key="i">
                        <span class="advice-title">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><g><path id="Vector" d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                            {{ Advice.Title }}
                        </span>
                        <span class="advice-text">{{ Advice.Text }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";

const toast = useToast()

export default {
    name: 'Item',

    data () {
        return {
            Advices: [],
            Score: false
        }
    },

    mounted () {
        this.GetItem()
    },

    methods: {
        GetItem () {
            this.$root.SetLoader(true)
            this.$http.get(`https://bouwbond.app/api/v1/checks/${this.$route.params.id}`).then((response) => {
                this.$root.SetLoader(false)
                if(response.data.score) {
                    this.Advices = response.data.advice
                    this.Score = response.data.score

                    this.$route.meta.Bar.Subtitle = 'Je bekijkt momenteel de ZZP Status Check'
                    this.$router.replace({query: {t: Date.now()}})
                } else {
                    this.$router.push('/checks')
                    toast.warning("Check niet gevonden. Probeer het nog een keer.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.item .tab {
    position: relative;
    height: calc(100% - 50px);
    gap: 50px;
    z-index: 1;
}

.item .tab .block {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 35px 25px 50px 25px;
    background: #fff;
    border: solid 1px #eff0f6;
    border-radius: 5px;
}

.item .tab .progress {
    width: 100%;
}

.item .tab .bubble {
    width: fit-content;
    padding: 2.5px 15px;
    background: #009fe3;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
}

.item .tab .title {
    width: 500px;
    font-weight: 600;
    font-size: 24px;
}

.item .tab .subtitle {
    width: 500px;
}

.item .tab .score {
    position: absolute;
    top: 150px;
    right: 100px;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #f7f8fc;
    border-radius: 50%;
}

.item .tab .score.green {
    background: #0fb981;
}

.item .tab .score.orange {
    background: #e2b34d;
}

.item .tab .score .top {
    font-size: 100px;
    line-height: 50px;
    font-weight: 600;
    margin: 30px 0 0 0;
    color: #fff;
}

.item .tab .score .bottom {
    font-size: 40px;
    color: #fff;
}

.item .tab .score .illustration {
    position: absolute;
    bottom: -50px;
    right: -150px;
    width: 280px;
}

.item .tab .advices {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 150px 0 0 0;
}

.item .tab .advices .advice {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 25px;
    background: #f3f8ff;
    border: solid 1px #dcdfff;
    border-radius: 10px;
}

.item .tab .advices .advice .advice-title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 20px;
}

.item .tab .advices .advice .advice-title svg {
    width: 30px;
    height: 30px;
    stroke: #333;
}

.item .tab .advices .advice .advice-text {
    white-space: pre-line;
}





@media only screen and (max-width: 1000px) {
    .item .tab .title,
    .item .tab .subtitle {
        width: 100%;
    }

    .item .tab .score {
        position: unset;
        width: 100%;
        height: unset;
        aspect-ratio: 1 / 1;
    }

    .item .tab .score .illustration {
        display: none;
    }

    .item .tab .advices {
        margin: 25px 0 0 0;
    }

    .item .tab .advices .advice .advice-title {
        display: block;
        margin: 0;
        word-wrap: break-word;
    }

    .item .tab .advices .advice .advice-title svg {
        display: none;
    }
}
</style>