<template>
    <div class="create">
        <div class="tab">
            <div class="block">
                <span class="title">Stap 1. Algemene zaken</span>
                <div class="questions">
                    <div class="question" v-for="(Question, i) in Questions" :key=i>
                        <span class="text" v-html="Question.Text"></span>
                        <div class="answers">
                            <span class="button" :class="{ selected: Questions[i].Answer == 'Y' }" @click="Questions[i].Answer = 'Y', this.$root.Vibrate()">Ja</span>
                            <span class="button" :class="{ selected: Questions[i].Answer == 'N' }" @click="Questions[i].Answer = 'N', this.$root.Vibrate()">Nee</span>
                            <span class="info" @click="Questions[i].InformationActive = !Questions[i].InformationActive, this.$root.Vibrate()" :class="{ active: Questions[i].InformationActive }">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><g><path id="Vector" d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                            </span>
                        </div>
                        <div class="information" v-if="Questions[i].InformationActive">
                            <span class="information-title">Toelichting op deze vraag</span>
                            <span class="information-text">{{ Questions[i].Information }}</span>
                            <img class="illustration" src="@/assets/Illustration/Advies.png" alt="Advice">
                        </div>
                    </div>
                </div>
            </div>
            <div class="block" v-if="Questions.filter(e => e.Answer == 'Y' && e.Attachment).length > 0">
                <span class="title">Stap 2. Bewijslast (optioneel)</span>
                <div class="files">
                    <div class="file" v-for="(Question, Ai) in Questions.filter(e => e.Answer == 'Y' && e.Attachment)" :key="Ai">
                        <span class="file-title">{{ Question.Attachment }}</span>
                        <FileUpload :class="{ selected: 1 == 1 }" @select="SelectFile(Question.Identifier, $event)" name="file[]" :multiple="false" accept="image/png,image/jpeg,application/pdf" :maxFileSize="1000000" chooseLabel="Selecteer een bestand" :showUploadButton="false" :showCancelButton="false" :fileLimit="1">
                            <template #header="{ chooseCallback, files }">
                                <Button class="p-upload-button" @click="chooseCallback(), this.$root.Vibrate()" :disabled="files.length > 0" :class="{ disabled: files.length > 0 }">
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5535 2.49392C12.4114 2.33852 12.2106 2.25 12 2.25C11.7894 2.25 11.5886 2.33852 11.4465 2.49392L7.44648 6.86892C7.16698 7.17462 7.18822 7.64902 7.49392 7.92852C7.79963 8.20802 8.27402 8.18678 8.55352 7.88108L11.25 4.9318V16C11.25 16.4142 11.5858 16.75 12 16.75C12.4142 16.75 12.75 16.4142 12.75 16V4.9318L15.4465 7.88108C15.726 8.18678 16.2004 8.20802 16.5061 7.92852C16.8118 7.64902 16.833 7.17462 16.5535 6.86892L12.5535 2.49392Z" /><path d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z" /></svg>
                                    Selecteer een bestand
                                </Button>
                            </template>
                            <template #empty>
                                <span class="p-empty">of sleep hier je bestand om te uploaden</span>
                            </template>
                        </FileUpload>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <div class="button" @click="SaveCheck(), this.$root.Vibrate()" :class="{ active: Questions.filter(e => e.Answer).length == Questions.length }">Check uitvoeren</div>
            </div>
        </div>
    </div>
</template>

<script>
import FileUpload from 'primevue/fileupload';
import { useToast } from "vue-toastification";

const toast = useToast()

export default {
    name: 'CheckCreate',

    components: {
        FileUpload
    },

    data () {
        return {
            Questions: [],
            Files: []
        }
    },

    mounted () {
        this.GetQuestions()
    },

    methods: {
        GetQuestions () {
            this.$root.SetLoader(true)
            this.$http.get(`https://bouwbond.app/api/v1/checks/questions`).then((response) => {
                if(response.data.questions) { this.Questions = response.data.questions }
                this.$root.SetLoader(false)
            })
        },

        SelectFile (e, a) {
            if(a.files[0]['type'] == 'image/png') {
                this.Files.push(
                    new File([a.files[0]], e + '.png', {
                        type: "image/png",
                        lastModified: new Date()
                    })
                )
            } else if(a.files[0]['type'] == 'image/jpeg') {
                this.Files.push(
                    new File([a.files[0]], e + '.jpeg', {
                        type: "image/jpeg",
                        lastModified: new Date()
                    })
                )
            } else if(a.files[0]['type'] == 'application/pdf') {
                this.Files.push(
                    new File([a.files[0]], e + '.pdf', {
                        type: "application/pdf",
                        lastModified: new Date()
                    })
                )
            }
        },

        SaveCheck () {
            this.$root.SetLoader(true)
            this.$http.post('https://bouwbond.app/api/v1/checks', {
                Items: this.Questions,
                Files: this.Files
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                if(response.data.identifier) {
                    this.$router.push('/checks/' + response.data.identifier)
                } else {
                    toast.warning("Check kan niet worden opgeslagen. Probeer het nog een keer.", {
                        position: "top-right",
                        timeout: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: false,
                        icon: true
                    })
                    this.$root.SetLoader(false)
                }
            })
        }
    }
}
</script>

<style scoped>
.create .tab {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: calc(100% - 50px);
    gap: 50px;
    z-index: 1;
}

.create .tab .block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    padding: 35px 25px 50px 25px;
    background: #fff;
    border: solid 1px #eff0f6;
    border-radius: 5px;
}

.create .tab .block .title {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 30px;
}

.create .tab .block .questions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.create .tab .block .questions .question {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
}

.create .tab .block .questions .question .text {
    margin: 0 auto 0 0;
}

.create .tab .block .questions .question .text:deep(b) {
    font-weight: 600;
}

.create .tab .block .questions .question .answers {
    display: flex;
    gap: 10px;
}

.create .tab .block .questions .question .answers .button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100px;
    height: 60px;
    font-weight: 600;
    border: solid 1px #d6d6d6;
    border-radius: 5px;
    cursor: pointer;
}

.create .tab .block .questions .question .answers .button.selected {
    background: #3e3d64;
    color: #fff;
}

.create .tab .block .questions .question .answers .info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 60px;
    height: 60px;
    font-weight: 600;
    border: solid 1px #d6d6d6;
    border-radius: 5px;
    cursor: pointer;
}

.create .tab .block .questions .question .answers .info.active {
    background: #cdecf9;
    border-color: #009fe3;
}

.create .tab .block .questions .question .answers .info svg {
    width: 24px;
    height: 24px;
    stroke: #009fe3;
}

.create .tab .block .questions .question .information {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    margin: 25px 0 75px 0;
    padding: 25px;
    background: #cdecf9;
    border: solid 1px #009fe3;
    border-radius: 5px;
}

.create .tab .block .questions .question .information .information-title {
    font-weight: 600;
}

.create .tab .block .questions .question .information .information-text {
    width: calc(100% - 150px);
}

.create .tab .block .questions .question .information .illustration {
    position: absolute;
    bottom: -25px;
    right: -25px;
    width: 200px;
}

.create .tab .block .files {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.create .tab .block .files .file {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: calc((100% / 3) - 17px);
}

.create .tab .block .files .file .file-title {
    font-weight: 600;
}

.create .tab .block .files .file:deep(.p-upload-button) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 50px;
    background: #009fe3;
    color: #fff;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
}

.create .tab .block .files .file:deep(.p-upload-button.disabled) {
    pointer-events: none;
    opacity: 0.5;
}

.create .tab .block .files .file:deep(.p-upload-button svg) {
    width: 24px;
    height: 24px;
    fill: #fff;
}

.create .tab .block .files .file:deep(.p-fileupload) {
    border: dashed 1px #009fe3;
    border-radius: 10px;
}

.create .tab .block .files .file:deep(.p-fileupload-file) {
    position: relative;
}

.create .tab .block .files .file:deep(.p-fileupload-file-actions) {
    position: absolute;
    bottom: 0;
    right: 0;
}

.create .tab .block .files .file:deep(.p-fileupload-choose-button) {
    width: 100%;
}

.create .tab .block .files .file:deep(.p-fileupload-file-info) {
    width: 100%;
}

.create .tab .block .files .file:deep(.p-fileupload-file-name) {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.create .tab .block .files .file:deep(.p-fileupload-file-thumbnail) {
    display: none;
}

.create .tab .block .files .file:deep(.p-badge-warn) {
    display: none;
}

.create .tab .block .files .file:deep(.p-empty) {
    display: flex;
    align-items: center;
    height: 100px;
    padding: 0 15px;
    text-align: center;
    background: #f7f8fc;
    border-radius: 10px;
}

.create .tab .buttons {
    width: 100%;
}

.create .tab .buttons .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    margin: 0 0 35px auto;
    background: #009fe3;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.5;
}

.create .tab .buttons .button.active {
    pointer-events: all;
    opacity: 1;
}





@media only screen and (max-width: 1000px) {
    .create .tab .block .questions {
        gap: 25px;
    }

    .create .tab .block .questions .question {
        gap: 5px;
    }

    .create .tab .block .questions .question .information {
        margin: 25px 0;
    }

    .create .tab .block .questions .question .information .information-text {
        width: 100%;
        word-break: break-word;
    }

    .create .tab .block .questions .question .information .illustration {
        display: none;
    }
}
</style>