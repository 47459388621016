<template>
    <div class="news">
        <div class="item" v-for="(Item, i) in News" :key="i">
            <div class="thumbnail" :style="{ background: 'url(' + Item.Thumbnail + ')' }"></div>
            <div class="information">
                <span class="title">{{ Item.Title }}</span>
                <span class="description" v-html="Item.Content"></span>
                <a :href="Item.Link" target="_blank" class="more">Lees verder</a>
            </div>
        </div>
        <a v-if="News.length > 5" href="https://bouwbond.nl/nieuws" target="_blank" class="more-news" @click="this.$root.Vibrate()">Bekijk meer nieuws</a>
    </div>
</template>

<script>
export default {
    name: '404',

    data () {
        return {
            News: []
        }
    },

    mounted () {
        this.GetNews()
    },

    methods: {
        GetNews () {
            this.$root.SetLoader(true)
            this.$http.get('https://bouwbond.app/api/v1/auth/news').then((response) => {
                this.News = response.data.news

                this.$root.SetLoader(false)
            })
        }
    }
}
</script>

<style scoped>
.news {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.news .item {
    display: flex;
    gap: 0 25px;
    padding: 25px;
    background: #fff;
    border-radius: 5px;
    z-index: 1;
}

.news .item .thumbnail {
    width: 100px;
    height: 85px;
    margin: 8px 0 0 0;
    background-position: 50% !important;
    background-size: cover !important;
    border-radius: 5px;
}

.news .item .information {
    width: calc(100% - 100px);
}

.news .item .information .title {
    width: calc(100% - 125px);
    font-weight: 500;
    font-size: 20px;
}

.news .item .information .more {
    color: #009fe3;
    font-weight: 600;
    text-underline-offset: 5px;
}

.news .more-news {
    width: fit-content;
    margin: auto;
    padding: 15px 25px;
    color: #009fe3;
    font-weight: 600;
    text-decoration: none;
    border: solid 1px #009fe3;
    border-radius: 5px;
}





@media only screen and (max-width: 1000px) {
    .news .item {
        flex-direction: column;
        gap: 15px;
    }

    .news .item .thumbnail {
        width: 100%;
        height: 140px;
    }

    .news .item .information {
        width: 100%;
    }
}
</style>