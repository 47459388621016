<template>
    <div class="bar" v-if="this.$route.meta.Bar" :style="{ background: this.$route.meta.Bar.Background }" @scroll="Test">
        <svg class="mobile-menu" :class="{ scrolled: scrolled }" @click="this.$root.$refs.Menu.SetMenu(true)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M4 12H20M4 8H20M4 16H12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
        <div class="content" :style="{ background: this.$route.meta.Bar.Background }">
            <span class="title" :class="{ stack: this.$route.meta.Stack }">
                <div class="icon" v-if="this.$route.meta.Bar.SVG" v-html="this.$route.meta.Bar.SVG"></div>
                {{ this.$route.meta.Bar.Title.replace(', %User_Name%', ', ' + UserName) }}
            </span>
            <span class="subtitle" v-if="BBM == 'ON'">{{ this.$route.meta.Bar.SubtitleON }}</span>
            <span class="subtitle" v-else-if="BBM == 'OG'">{{ this.$route.meta.Bar.SubtitleOG }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Bar',

    props: ['scrolled'],

    data () {
        return {
            UserName: localStorage.getItem('BBN') ?? "",
            Scrolled: false,
            BBM: localStorage.getItem('BBM')
        }
    }
}
</script>

<style scoped>
.bar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.bar .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0 75px;
    overflow: unset;
}

.bar .content .title {
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 800px;
    margin: 0;
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    transition: .25s;
}

.bar .content .title.stack {
    margin: -35px 0 0 0;
}

.bar .content .title .icon {
    display: flex;
    align-items: center;
}

.bar .content .title .icon:deep(svg) {
    fill: #fff;
}

.bar .content .title .icon:deep(svg) {
    width: 38px;
    height: 38px;
}

.bar .content .subtitle {
    max-width: 800px;
    color: #fff;
}

.mobile-menu {
    display: none;
}




@media only screen and (max-width: 1000px) {
    .bar {
        height: unset !important;
    }

    .bar .content {
        gap: 15px;
        padding: calc(env(safe-area-inset-top) + 80px) 40px 70px 40px;
    }

    .bar .content .title {
        align-items: flex-start;
        gap: 10px;
        line-height: 30px;
    }

    .bar .content .title.stack {
        margin: 0;
    }

    .bar .content .title .icon {
        margin: -5px 0 0 0;
    }

    .mobile-menu {
        display: block;
        width: 48px;
        height: 48px;
        position: fixed;
        top: calc(env(safe-area-inset-top) + 15px);
        left: 40px;
        background: transparent;
        border-radius: 5px;
        transition: .15s;
        z-index: 777;
    }

    .mobile-menu.scrolled {
        background: #2a2532;
    }
}
</style>